import AuthContext from '@jetshop/core/components/AuthContext/AuthContext';
import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';

import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import MaxWidth from '../Layout/MaxWidth';
import TrendButton from '../ui/Button';
import { smallCaps } from '../ui/Headings';
import IntlContext from '@jetshop/intl/context';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    flex: 1 1 calc(50% - 1em);
  }
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: black;
  text-align: right;
  font-size: 0.875em;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

const FormWrapper = styled('div')`
  min-width: 320px;
`;

class LogInPage extends Component {
  render() {
    return (
      <IntlContext.Consumer>
        {t => (
          <AuthContext.Consumer>
            {({ loggedIn }) => (
              <MaxWidth css={{ alignItems: 'center', marginTop: '2em' }}>
                {loggedIn ? (
                  <Redirect to={t('/my-pages')} />
                ) : (
                  <LogInFormProvider>
                    {({ status, isSubmitting, isValid }) => (
                      <FormWrapper>
                        <header
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '0.75rem'
                          }}
                        >
                          <h1 className={smallCaps} style={{ marginBottom: 0 }}>
                            {t('Login')}
                          </h1>

                          <TextLink to={t('/signup')} className="signup-link">
                            {t('Not yet a member? Sign Up')}
                          </TextLink>
                        </header>
                        <Input
                          type="email"
                          name="email"
                          label={t('E-mail address')}
                        />
                        <Input
                          type="password"
                          name="password"
                          label={t('Password')}
                        />

                        {status && status.globalError && (
                          <GlobalError css={{ marginBottom: '2em' }}>
                            {status.globalError}
                          </GlobalError>
                        )}

                        <section className={actionWrapper}>
                          <StyledTrendButton
                            type="submit"
                            disabled={!isValid}
                            loading={isSubmitting}
                            loadingText={t('Hold on a moment...')}
                          >
                            {t('Log in')}
                          </StyledTrendButton>
                          <TextLink to={t('/forgot-password')}>
                            {t('Forgot password?')}
                          </TextLink>
                        </section>
                      </FormWrapper>
                    )}
                  </LogInFormProvider>
                )}
              </MaxWidth>
            )}
          </AuthContext.Consumer>
        )}
      </IntlContext.Consumer>
    );
  }
}
export default withRouter(LogInPage);
